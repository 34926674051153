section.about {
    display: flex;
    justify-content: space-between;
    min-height: 400px;
    overflow: hidden !important;

}

section.about > div:nth-of-type(1) {
    width: 60%;
    display: flex;
    justify-content: center;
}

section.about .card-one {
    position: relative;
    width: 40%;
    z-index: 1;
}

section.about .card-two {
    width: 60%;
    position: relative;
    left: -18%;
}

section.about div img { width: 100%;}

section.about > div:nth-of-type(2) {
    width: 45%;
    position: absolute;
    right: 0;
    overflow-x: hidden !important;
}

section.about div:nth-of-type(2) h2 {
    color: var(--cor-secundaria);
    text-transform: uppercase;
    width: 100%;
    font-size: var(--title-size);
    font-style: italic;
    transition: .8s;

}

section.about div:nth-of-type(2) h2:hover {
    transform: skew(-11deg) scale(1.02) !important;
    transition: .8s;
    padding-left: 1rem;
}

section.about div:nth-of-type(2) p { width: 90% }

@media (max-width: 600px) {
    section.about {
        display: flex;
        height: 500px;
        position: relative;
        flex-direction: column-reverse;
    }

    section.about div:nth-of-type(1) {
        position: relative;
        width: 100%;
        align-items: center;
        padding-left: 5%;
        transform: scale(1.1);
    }
    section.about div:nth-of-type(2) {
        width: 100%;
        top: 0;
    }

    section.about div:nth-of-type(2) h2 { font-size: var(--title-size-mobile);}
    
}



/* SERVICES */
section.services {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    overflow: hidden;
    padding-left: 0;
}

section.services > div.title { width: 50% }

section.services > div h2 {
    font-size: var(--title-size);
    color: var(--cor-secundaria);
    text-transform: uppercase;
    font-style: italic;
}

section.services > div p { width: 60% }

section.services .cta {
    width: 50%;
    display: flex;
    flex-direction: column;
    left: 0;
}

section.services .cta button { width: 100% }
section.services .cta button {
    position: relative;
    font-size: 1rem;
    font-weight: bold;
    z-index: 5;
}
section.services .cta button:hover { background: linear-gradient(to right, #2580b3, #cbbacc) }


section.services .notebook {
    position: relative;
    display: flex;
    bottom: 0;
    width: 50%;
    justify-content: center;
    align-items: center;
}
section.services .notebook img { width: 100% }

section.services .notebook video {
    position: absolute;
    width: 78%;
    top: 6%;
    z-index: 3;
}

@keyframes btn-play {
    0% { transform: scale(0.85) }
    100% { transform: scale(1) }
}
section.services .notebook .play {
    position: absolute;
    width: 100px;
    z-index: 4;
    top: 20%;
    cursor: var(--cursor-pointer);
    animation: btn-play .8s infinite;
    opacity: .9;
}


@media (max-width: 800px) {
    section.about div:nth-of-type(2) p { width: 100% }
    section.services {
        margin-top: 5rem;
        flex-direction: column;
        align-items: center;
    }

    section.services > div.title, section.services > div.title p { width: 100% }

    section.services .cta { margin: 0 auto }
    
    section.services .notebook {
        margin-top: 1rem;
        width: 90%;
    }

    section.services .notebook .play {
        top: 50px;
        width: 90px;
    }
}



/* AOS */

.writeRun {
    display: inline-block;
    overflow: hidden;
}

.writeRun span {
    display: inline-block;
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(50%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
